import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

const RewardOptions = styled.div`
margin: 20px 0;

.cell-heading {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 2px;
  text-transform: uppercase;
}

.cell-description {
  text-align: center;
  text-transform: uppercase;
}

.cell-description-2 {
  margin: 0;
  font-size: 13px;
}

.cell-image {
  display: inline-block;
  padding: 5px 10px;
  max-width: 350px;
  margin: auto;
}

.top-artist-grid {
  margin-top: 32px;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.cell {
  padding: 10px;
  text-align: center;
  border-bottom: 1px solid #ddd;
}

.black-label {
  margin: 100px auto 100px;
  padding: 30px;
  background-color: #000;
  max-width: 160px;
  height: auto;
  text-align: center;
  border-radius: 20px;
}

.black-label p {
  color: #fff;
  font-weight: 800;
  text-transform: uppercase;
  font-size: 28px;
  line-height: 1.1em;
}

@media screen and (min-width: ${props => props.theme.responsive.medium}) {
  .top-artist-grid {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .cell {
    border-bottom: none;
  }

  .center-cell {
    border-left: 1px solid #000;
    border-right: 1px solid #000;
  }

  .cell-border-right {
    border-right: 1px solid #000;
  }

  .cell-border-bottom {
    border-bottom: 1px solid #000;
  }
}
`

const CustomList = styled.ul`
  line-height: 2em;
  margin: 0 auto 32px;
`

const CustomListItem = styled.li`
  &::before {
    content: '/';
    color: #000;
    margin-right: 5px;
  }
`

const PointSystem = styled.div`
  text-align: center;
  text-transform: uppercase;

  h4 {
    font-weight: 600;
  }

  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    margin-top: 32px;
  }
`

const RewardDescription = styled.p`
  text-align: center;
  font-size: 16px;
  margin: 10px 0;

  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    margin: 20px 0;
  }
`

const RewardsLevelTwo = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulAsset(
        sort: { fields: [description], order: [ASC] },
        filter: { description: { regex: "/.*rewards-tier-two.*/" } }
      ) {
        edges {
          node {
            id
            title
            file {
              url
            }
          }
        }
      }
    }
  `)

  const rewardImages = data.allContentfulAsset.edges

  return (
    <>
      <h2 style={{ textTransform: 'uppercase', fontWeight: '600' }} className="headline--medium center-align">Rewards Level II</h2>
      <RewardDescription>Artist Choice Between the following Level II Rewards:</RewardDescription>
      <RewardOptions>
        <div className="top-artist-grid">
          <div className="cell cell-border-right cell-border-bottom" key="key1">
            <h4 className="cell-heading">Option I</h4>
            <p className="cell-description">{rewardImages[0].node.title}</p>
            <img className="cell-image" src={rewardImages[0].node.file.url} alt={rewardImages[0].node.title} />
          </div>
          <div className="cell cell-border-right cell-border-bottom" key="key2">
            <h4 className="cell-heading">Option II</h4>
            <p className="cell-description">{rewardImages[1].node.title}</p>
            <img className="cell-image" src={rewardImages[1].node.file.url} alt={rewardImages[1].node.title} />
          </div>
          <div className="cell cell-border-bottom" key="key3">
            <h4 className="cell-heading">Option III</h4>
            <p className="cell-description">{rewardImages[2].node.title}</p>
            <img className="cell-image" src={rewardImages[2].node.file.url} alt={rewardImages[2].node.title} />
          </div>
          <div className="cell cell-border-right" key="key4">
            <h4 className="cell-heading">Option IV</h4>
            <p className="cell-description">{rewardImages[3].node.title}</p>
            <p className="cell-description-2">(choose one)</p>
            <img className="cell-image" src={rewardImages[3].node.file.url} alt={rewardImages[3].node.title} />
          </div>
          <div className="cell cell-border-right" key="key5">
            <h4 className="cell-heading">Option V</h4>
            <p className="cell-description">{rewardImages[4].node.title}</p>
            <img className="cell-image" src={rewardImages[4].node.file.url} alt={rewardImages[4].node.title} />
          </div>
          <div className="cell" key="key6">
            <h4 className="cell-heading">Option VI</h4>
            <p className="cell-description">$500 Gift Card</p>
            <div className="black-label">
              <p>$500</p>
            </div>
          </div>
        </div>
      </RewardOptions>
      <h4 style={{ textTransform: 'uppercase', fontWeight: '600' }} className="center-align">How to Earn</h4>
      <CustomList>
        <CustomListItem>Total Points Needed: 12,000+</CustomListItem>
        <CustomListItem>Qualification Period: 6 months</CustomListItem>
        <CustomListItem># Months Required w/ Monthly Minimum: 4 months</CustomListItem>
        <CustomListItem>Monthly Minimum Requirement: 1500 points per month</CustomListItem>
      </CustomList>
      <PointSystem>
        <h4>Point System</h4>
        <p>
          1 Point = 1 CV<br />
          100 Points = Per Active Direct Per Month*<br />
          200 Points = Per New Artist Sign Up
        </p>
      </PointSystem>
    </>
  )
}

export default RewardsLevelTwo
